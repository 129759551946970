/* eslint-disable key-spacing, quote-props */
const DEFAULT_TELEGRAM_LINK = 'https://t.me/schoolsynergy_bot';

export const TELEGRAM_LINKS = {
  'index':                                'https://salebot.site/b12496c731e105deef0434b3f221bf3f_1',
  'online-school':                        'https://salebot.site/ddbecef063ab4e17abed8338290014f7_1',
  'school-university':                    'https://salebot.site/34580ca0c3feb1212aaaab1df28f8a09_1',
  'externat':                             'https://salebot.site/1c8ef9b376d3dccaf1e82351786d07d9_1',
  'externat-dlya-vzroslyh':               'https://salebot.site/7a5edcba7c6c98ee25c480d2c31eaff8_1',
  'ege':                                  'https://salebot.site/a8ba4130c7e11354281ab5d15fe370bf_1',
  'oge':                                  'https://salebot.site/337813b4df86d24fff4d877bd12d1e43_1',
  'catalog-repetitor':                    'https://salebot.site/8c918dc24a09aae0dd7139fdd6263f40_1',
  'catalog-programmirovanie-dlya-detej':  'https://salebot.site/42be6c3489f2a5e3f3c5c8443c6637e8_1',
  'proforientacia':                       'https://salebot.site/a6e90a050e5bad4675d45e2c1db18229_1',
  'online-school-ceny':                   'https://salebot.site/3ccf7d18aa6fb494b0416927213ab13c_1',
  'ege-ceny':                             'https://salebot.site/062ff47da8f9d132e029dce3749de65c_1',
  'oge-ceny':                             'https://salebot.site/02402dc4fecf8b82bc3dbfe7a5eb6668_1',
  'articles':                             'https://salebot.site/d3f2ee305f1967bf2194440bdb87909d_1',
  'news':                                 'https://salebot.site/d10c05eb1b869b65d985aa8305ae74c8_1',
  'school':                               'https://salebot.site/25afe2a4afea72f15e291e13406cb9be_1',
  'education':                            'https://salebot.site/a04a00d981f4b9827152e1065d1eae04_1',
  'psychology':                           'https://salebot.site/c38caa6f433a4f11df405be5570c87ee_1',
  'ege-oge':                              'https://salebot.site/cc7e9f6e8be50ea946546774ea48e111_1',
  'lifestyle':                            'https://salebot.site/c94a9ba2a0c15adefadcefa4d1f03e81_1',
  'online-school-ceny-2':                 'https://salebot.site/5cddb84b6497f307a7826d4e2856d7cf_1',
};

export const getTelegramLink = (route) => {
  if (!route) return DEFAULT_TELEGRAM_LINK;

  const routePath = route?.path ? route.path.replace('/', '') : null;
  return TELEGRAM_LINKS[route?.name] || TELEGRAM_LINKS[routePath] || DEFAULT_TELEGRAM_LINK;
};

export default {
  TELEGRAM_LINKS,
  getTelegramLink,
};
